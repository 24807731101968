"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
import { useRouter, useParams } from "next/navigation";
import { useTranslations } from "next-intl";
import { format, startOfDay, endOfDay, addDays } from "date-fns";
import { es } from "date-fns/locale";

import { FavoriteLeague } from "../../LeaguesOfTheMoment/LeaguesOfTheMoment";

import { useLocale } from "@/hooks/useLocale";

import "../_fixtures.scss";

const NextFixtures = ({
  favoriteLeagues,
}: {
  favoriteLeagues: FavoriteLeague[];
}) => {
  const router = useRouter();
  const params = useParams();

  const { locale } = params;

  const [fixturesByLeague, setFixturesByLeague] = useState<any[]>([]);
  const [filteredFixtures, setFilteredFixtures] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [expandedLeagues, setExpandedLeagues] = useState<Set<number>>(
    new Set(favoriteLeagues.map(({ league }) => league))
  );
  const [selectedDay, setSelectedDay] = useState<number>(0); // 0: Today, 1: Tomorrow, ...

  const tFixtures = useTranslations('fixtures');
  const tDates = useTranslations('dates');
  const tLayout = useTranslations('layout');

  const dateFnsLocale = useLocale(locale as string);

  const DAYS = [
    tDates("today"),
    tDates("tomorrow"),
    format(addDays(new Date(), 2), "MMM dd", { locale: dateFnsLocale }).toUpperCase(),
    format(addDays(new Date(), 3), "MMM dd", { locale: dateFnsLocale }).toUpperCase()
  ];

  const dateRanges = [
    { start: startOfDay(new Date()), end: endOfDay(new Date()) },
    { start: startOfDay(addDays(new Date(), 1)), end: endOfDay(addDays(new Date(), 1)) },
    { start: startOfDay(addDays(new Date(), 2)), end: endOfDay(addDays(new Date(), 2)) },
    { start: startOfDay(addDays(new Date(), 3)), end: endOfDay(addDays(new Date(), 3)) },
  ];

  useEffect(() => {
    async function fetchFixtures() {
      try {
        const res = await fetch("/api/fixtures/next", { cache: "no-store" });
        if (!res.ok) throw new Error("Failed to fetch next fixtures");
        const data = await res.json();
        setFixturesByLeague(data);
      } catch (err) {
        console.error("Error fetching fixtures:", err);
        setError("Failed to fetch next fixtures");
      }
    }
    fetchFixtures();
  }, []);

  useEffect(() => {
    const { start, end } = dateRanges[selectedDay];

    const filtered = fixturesByLeague.map((league: any) => ({
      ...league,
      fixtures: league.fixtures.filter((fixture: any) => {
        const fixtureDate = new Date(fixture.fixture.date);
        return fixtureDate >= start && fixtureDate <= end;
      }),
    })).filter((league: any) => league.fixtures.length > 0);
    setFilteredFixtures(filtered);
  }, [selectedDay, fixturesByLeague]);

  const toggleLeague = (leagueId: number) => {
    const newExpandedLeagues = new Set(expandedLeagues);
    if (newExpandedLeagues.has(leagueId)) {
      newExpandedLeagues.delete(leagueId);
    } else {
      newExpandedLeagues.add(leagueId);
    }
    setExpandedLeagues(newExpandedLeagues);
  };

  const handleFixtureClick = (teamId: number) => {
    router.push(`/trends?searchType=team&searchId=${teamId}`);
  };

  if (error) return <div>Something happened. Try refreshing.</div>;
  if (fixturesByLeague.length < 1) return <div>{tLayout('loading')}.....</div>;

  return (
    <>
      <p className="h5 m-2 text-start">{tFixtures("nextFixtures")}</p>
      <div className="d-flex justify-content-center my-2">
        {DAYS.map((day, index) => (
          <button
            key={index}
            className={`btn btn-sm ${selectedDay === index ? "btn-primary-selected" : "btn-primary-outline"}`}
            onClick={() => setSelectedDay(index)}
          >
            {day}
          </button>
        ))}
      </div>
      <p className="text-center" style={{fontSize: "0.9rem"}}>*Presiona cualquier partido para ir a sus tendencias</p>
      <div
        className="fixturesList glass-segment mt-0"
      >
        {filteredFixtures.map((league: any) => (
          <React.Fragment key={league.league.id}>
            <div
              className="d-flex align-items-center p-1"
              style={{
                backgroundColor: "var(--text-color)",
                cursor: "pointer",
              }}
              onClick={() => toggleLeague(league.league.id)}
            >
              <div className="col-4">
                <Image
                  src={league.league.flag ?? league.league.logo}
                  alt={`${league.league.country} flag`}
                  width={20}
                  height={20}
                />
                <small
                  className="ps-1"
                  style={{ color: "var(--primary-color)" }}
                >
                  {league.league.country}
                </small>
              </div>
              <div className="col-7">
                <Image
                  src={league.league.logo}
                  alt={`${league.league.name} logo`}
                  width={20}
                  height={20}
                />
                <small
                  className="ps-1"
                  style={{ color: "var(--primary-color)" }}
                >
                  {league.league.name}
                </small>
              </div>
              <div className="col-1 text-center">
                  <Image
                    className="d-none logo-light"
                    src={
                      expandedLeagues.has(league.league.id)
                        ? "/icons/arrow-up-dark.png"
                        : "/icons/arrow-down-dark.png"
                    }
                    alt="Toggle League Dark"
                    width={13}
                    height={13}
                    onClick={() => toggleLeague(league.league.id)}
                    style={{ cursor: "pointer" }}
                  />
                  <Image
                    className="d-none logo-dark"
                    src={
                      expandedLeagues.has(league.league.id)
                        ? "/icons/arrow-up-light.png"
                        : "/icons/arrow-down-light.png"
                    }
                    alt="Toggle League Light"
                    width={13}
                    height={13}
                    onClick={() => toggleLeague(league.league.id)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
            </div>

            {expandedLeagues.has(league.league.id) && (
              <>
                {league.fixtures.map((fixture: any) => (
                  <div
                    key={fixture.fixture.id}
                    className="d-flex align-items-center py-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleFixtureClick(fixture.homeTeam.id)}
                  >
                    <div className="col-1 d-flex flex-column align-items-center">
                      <Image
                        src={fixture.homeTeam.logo}
                        alt={fixture.homeTeam.name}
                        width={20}
                        height={20}
                        className="mb-2"
                      />
                      <Image
                        src={fixture.awayTeam.logo}
                        alt={fixture.awayTeam.name}
                        width={20}
                        height={20}
                      />
                    </div>
                    <div className="col-6 d-flex flex-column align-items-start ps-1">
                      <p className="mb-2">{fixture.homeTeam.name}</p>
                      <p className="">{fixture.awayTeam.name}</p>
                    </div>
                    <div className="col-5 d-flex flex-column align-items-center">
                      <p
                        className="mb-2"
                        style={{ color: "var(--secondary-text-color)" }}
                      >
                        {fixture.fixture.round}
                      </p>
                      <p style={{ color: "var(--secondary-text-color)" }}>
                        {format(new Date(fixture.fixture.date), "HH:mm", { locale: es })}
                      </p>
                    </div>
                  </div>
                ))}
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default NextFixtures;
