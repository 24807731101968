import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"dmSans\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/src/app/[locale]/test.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/atoms/AdsBanners/AdLeftBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/atoms/AdsBanners/AdTopBanner.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/atoms/CTABanner/_ctaBanner.scss");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/atoms/GamblingAffiliation/_gaBanners.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/atoms/GamblingAffiliation/Multibanner300x250.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/atoms/ThemeProvider/ThemeProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/molecules/Fixtures/LiveFixtures/LiveFixtures.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/molecules/Fixtures/NextFixtures/NextFixtures.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/molecules/GATrackers/ThemeAnalyticsTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/molecules/LeaguesOfTheMoment/LeaguesOfTheMoment.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/molecules/NewsletterForm/NewsletterForm.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/organisms/Footer/_footer.scss");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/molecules/SocialNetworks/_socialNetworks.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src4115396859/src/football-trends-face/src/components/organisms/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src4115396859/src/football-trends-face/src/styles/main.scss");
